/**
 * @file page.js
 * This file provides the page component, which can be used to add features
 * available to all pages.
 *
 */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import SiteHeader from '../../container/site-header';
import { Header } from '../common';

export default props => {
  const className = props.className || ""
  return (
    <>
      <Header />
      <div className={`page ${props.className}`}>
        {props.children}
      </div>
    </>
  );
}

