import React from 'react';
import { classNames as cn } from 'Lib';

export const useModal = (props={}) => {

  const [visible, setVisible] = React.useState(props.visible ?? false);
  const [type, setType] = React.useState(props.type);
  const show = type => {
    if (type) setType(type);
    setVisible(true);
  }
  const hide = () => setVisible(false);
  const toggle = () => setVisible(!visible);
  return {
    hide,
    setType,
    show,
    toggle,
    type,
    visible
  }
}

export const Modal = ({children, className, controls}) => {
  return (
    <div
      className={cn({
        "modal-container": true,
        [className]: true,
        active: controls.visible
      })}
    >
      <div className="modal">
        {children}
      </div>
    </div>
  );
}

