import React from 'react'
import SocialButtons from 'Components/social-buttons';

const specialties = [
  "JavaScript",
  "TypeScript",
  "React.js",
  "Node.js"
];

export const Cover = props => (
  <section className="home-title">
    <div className="title-wrapper">
      <div className="title-container">
        <div className="portait">
          <img
            className="portrait"
            src="/img/jackbrown-io-thumbnail_grayscale.png"
            alt="Portrait: Jack Brown"
          />
        </div>
        <div className="details">
          <h1>{"Jack Brown"}</h1>
          <div>
            <h2>{"Software Engineer"}</h2>
            <h2>{"Port Townsend, WA, USA"}</h2>
          </div>
        </div>
      </div>
      <p className="description">
        {specialties.map(str => (
          <span key={str} className="specialty">{str}</span>
        ))}
      </p>
      <SocialButtons />
    </div>
  </section>
)

