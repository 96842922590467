import React, { useEffect, useRef } from 'react';

export const checkIsMounted = () => {

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false; };
  }, []);

  return isMounted && isMounted.current;
}

