import React from 'react';
import { Link } from 'react-router-dom';
import { mainMenuNavItems } from '../../config/main-nav-menu-items';

export const MenuAccessButton = ({onClick}) => (
  <button className="menu-access primary-color" onClick={onClick}>
    <img src="/img/icon/menu.svg" alt="Main menu." />
  </button>
)

export const MenuItems = ({items, closeMenu}) => items.map(item => (
  <li key={item.link}>
    <Link to={item.link} onClick={closeMenu}>
      {item.itemText}
    </Link>
  </li>
));

export const Header = props => {

  const [showMenu, setShowMenu] = React.useState(false);
  const toggleMenu = () => setShowMenu(!showMenu);
  const menu = React.useRef();

  const closeMenu = () => setShowMenu(false);

  React.useEffect(() => {
    if (showMenu && menu.current) {
      menu.current.style.top = "4rem";
    }
    else if (menu.current && menu.current.clientHeight) {
      menu.current.style.top = `-${menu.current.clientHeight}px`;
    }
  }, [showMenu, menu]);

  return (
    <>
      <header>
        <nav>
          <ul className="main-menu">
            <MenuItems items={mainMenuNavItems} />
          </ul>
          <MenuAccessButton onClick={toggleMenu} />
        </nav>
      </header>
      <nav className="mobile" ref={menu}>
        <ul className="mobile-menu">
          <MenuItems closeMenu={closeMenu} items={mainMenuNavItems} />
        </ul>
      </nav>
    </>
  );
}

