import axios from 'axios';

export const CONNECT = 'connect';
export const DELETE = 'delete';
export const GET = 'get';
export const HEAD = 'head';
export const OPTIONS = 'options';
export const POST = 'post';
export const PUT = 'put';

export const ROOT_URL = '';
export const API_URL = `${ROOT_URL}/api`;

const DEFAULT_HEADERS = {
  //"Referrer-Policy": "no-referrer"
}

export const request = (method, url, config={}) => {

  const headers = (config.headers)
    ? { ...DEFAULT_HEADERS, ...config.headers }
    : DEFAULT_HEADERS

  return axios({
    headers,
    method,
    url,
    data: config.data
  })
}

