import React from 'react';
import { DualRing as LoadingSpinner } from 'Components/loading';

export const Submit = props => (
  (props.submitting) ? (
    <LoadingSpinner className="submit disabled primary-color" />
  ) : (
    <input
      className="primary-color"
      type="submit"
      form={props.form}
      value={props.text || "Submit"}
    />
  )
)

