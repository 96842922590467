import React from 'react';
import { Modal, useModal } from '../Modals';

export const cloneChildren = (children, newProps={}) => (
  React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...newProps });
    }
    return child;
  })
);

export const Form = props => {
  const { className, name, onSubmit, validator } = props;
  //const successModal = useModal
  return (
    <form className={className || ""} id={name} onSubmit={onSubmit}>
      {cloneChildren(props.children, { validator })}
    </form>
  );
}

