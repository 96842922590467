import React from 'react';
import { Input } from './Input';
//import { textIsValid } from 'lib/validators';
import { useFieldValidator } from 'Lib/hooks';

// FIXME 12/28/2022: DUPLICATE? See validators.
const isEmpty = str => !str || str === "";

// FIXME 12/28/2022: Duplicate? See jbvalidator.
// 12/30/2022: jbvalidator provides isEmpty. The input validator is specific
// to the inputs in this library.
const textIsValid = required => value => {
  if (required && isEmpty(value)) {
    throw new Error("This field is required.");
  }
}

const DEFAULT_FIELD_TEST = textIsValid

export const TextInput = props => {

  const validateField = useFieldValidator({
    ...props, test: props.test || DEFAULT_FIELD_TEST
  });

  const blurAndValidate = e => {
    props.onBlur(e);
    validateField(e);
  }

  const onBlur = (() => {
    if (props.validateOnBlur) {
      return (props.onBlur) ? blurAndValidate : validateField
    }
    return props.onBlur || function() {};
  })();

  return (
    <span className={`${props.className || ""} input`}>
      <Input
        error={props.error}
        label={props.label}
        name={props.name}
        onBlur={onBlur}
        onChange={props.onChange}
        onFocus={props.onFocus}
        required={props.required}
        type={props.password ? "password" : "text"}
        value={props.value}
      />
    </span>
  );
}

