import React from 'react';
import { useHistory } from 'react-router-dom';
import Page from 'Components/pages/page';

export default () => {

  const history = useHistory();
  const [countdown, setCountdown] = React.useState(5);
  const [interval, _setInterval] = React.useState(null);

  const goHome = () => {
    history.push({ pathname: '/'});
  }

  React.useEffect(() => {
    const interval = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);
    _setInterval(interval);
    const timeout = setTimeout(goHome, 5000);
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    }
  }, []);

  React.useEffect(() => {
    if (countdown === 0 && interval) {
      clearInterval(interval);
    }
  }, [countdown, interval]);

  return (
    <Page className="not-found">
      <section>
        <h1>404: Not found.</h1>
        <p className="subtitle">{"Sorry! We can't find what you're looking for."}</p>
        {countdown > 0 && (
          <p className="redirect">{`Redirecting to the home page in ${countdown} seconds.`}</p>
        )}
        <button className="primary-color" onClick={goHome}>
          {"Home"}
        </button>
      </section>
    </Page>
  )
}

