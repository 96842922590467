import React from 'react';

const fields = {
  ORG_NAME: "org_name",
  CITY: "city",
  STATE: "state",
  DATE_START: "date_start",
  DATE_END: "date_end",
  ORG_SUMMARY: "org_summary",
  JOB_TITLE: "title",
}

// dates: string[]
const getResumeDates = dates => dates.map(d => (
  (d) ? new Date(d).toLocaleDateString("en-US", { month: "short", year: "numeric" })
    : "Present"
));

export const ResumeEntry = ({entry}) => {

  //console.log(entry);
  const [startDate, endDate] = getResumeDates([
    entry[fields.DATE_START],
    entry[fields.DATE_END]
  ]);

  return (
    <li className="resume">
      <div className="section-header">
        <h2>{entry[fields.ORG_NAME]}</h2>
        <p className="address">{`${entry[fields.CITY]}, ${entry[fields.STATE]}`}</p>
        <p className="date">{`${startDate} - ${endDate}`}</p>
      </div>
      <h3>{entry[fields.JOB_TITLE]}</h3>
      <p className="org-summary">{entry[fields.ORG_SUMMARY]}</p>
      <ul>
        {entry.details?.map(detail => null)}
      </ul>
    </li>
  )
}

