import React from 'react';
//import { textIsValid } from 'lib/validators';
import { useFieldValidator } from 'Lib/hooks';
import { classNames as cl, isString } from 'Lib';
import { FieldErrorMessage } from './';

// DUPLICATE? See validators.
const isEmpty = str => !str || str === "";

// Move this to validators. See also: TextInput
const textIsValid = required => value => {
  if (required && isEmpty(value)) {
    throw new Error("This field is required.");
  }
}

const DEFAULT_FIELD_TEST = textIsValid

export const Textarea = props => {

  const validateField = useFieldValidator({
    ...props, test: props.test || DEFAULT_FIELD_TEST
  });

  const blurAndValidate = e => {
    props.onBlur(e);
    validateField(e);
  }

  const onBlur = (() => {
    if (props.validateOnBlur) {
      return (props.onBlur) ? blurAndValidate : validateField
    }
    return props.onBlur || function() {};
  })();

  const onChange = props.onChange || function() {};
  const update = e => onChange(e.target.value);

  return (
    <span className={`${props.className || ""} input`}>
      <label
        className={props.required ? "required" : ""}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <textarea
        className={cl({
          control: true,
          error: props.error
        })}
        name={props.name}
        onBlur={onBlur}
        onChange={update}
        onFocus={props.onFocus}
        type={props.type}
        value={props.value || ""}
      ></textarea>
    {/*error={props.error}
        label={props.label}
        name={props.name}
        onBlur={onBlur}
        onChange={props.onChange}
        onFocus={props.onFocus}
        required={props.required}
        type={props.password ? "password" : "text"}
        value={props.value}
      />
      */}
      <FieldErrorMessage error={props.error} />
    </span>
  );
}

