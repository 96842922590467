import React from 'react';
import { isString } from 'Lib';

export const FieldErrorMessage = ({error}) => {
  if (!error || !isString(error)) return null;
  return (
    <span className="form-field-error error">{error}</span>
  )
}

