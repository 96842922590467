import React from 'react';
import { request, API_URL, POST } from 'Lib/network';

export const createInquiry = props => {
  const url = `${API_URL}/contact`;
  const {
    first_name,
    last_name,
    email,
    message
  } = props;
  return request(POST, url, { data: {
    first_name,
    last_name,
    email,
    message
  }});
}

