import React, { useEffect, useRef, useState } from 'react';
import { checkIsMounted } from '../check-is-mounted';
import { useFormValidation } from './validation';
import { deleteKey, mergeObjects } from 'Lib/util';

/**
 * @function useForm
 * @param fields: { [key: string]: string }
 * @param initialState: { [key: string]: any }
 * @return { [key: string]: (value) => void }
 **/
export const useForm = props => {

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(false);
  const [formState, setFormState] = useState(props?.initialState || {});
  const [submitting, setSubmitting] = useState(false);
  const isMounted = checkIsMounted();

  const reset = () => {
    setFormState({});
    setErrors({});
  }

  const _set = React.useCallback(fn => { setFormState(fn); }, []);

  const setError = React.useCallback((field, message) => {
    setErrors(prev => ({ ...prev, [field]: message }));
  }, []);

  const validator = useFormValidation({ formState, setError, setErrors });

  const mergeErrors = React.useCallback(errors => {
    setErrors(prev => mergeObjects(prev, errors));
  }, []);

  const clearError = React.useCallback(field => {
    setErrors(prev => {
      if (!prev[field]) return prev;
      return deleteKey(field, prev);
    });
  }, []);

  const handleSubmitError = error => {
    if (error.response) {
      mergeErrors(error.response.data.errors);
      setMessage(error.response.data.message);
    }
    else if (error.message) {
      setMessage(error.message);
    }
    else {
      setMessage("An error has occurred.");
    }
  }

  /**
   * @param submit: (formState: {}) => Promise
   * Handle custom resolve/reject logic here. Make sure to re-throw the
   * error.
   **/
  const onSubmit = submit => e => {
    e.preventDefault();
    setMessage(false);
    return new Promise(() => {
      if (!validator.validate()) return;
      setSubmitting(true);
      submit(formState)
        .catch(handleSubmitError)
        .finally(() => {
          if (isMounted) setSubmitting(false);
        })
    });
  }

  const toggleCheckbox = field => {
    setFormState(prev => ({ ...prev, [field]: !prev[field] }));
  }

  const update = React.useCallback(field => value => {
    setFormState(prev => ({ ...prev, [field]: value }));
    clearError(field);
  }, []);

  /**
   * @function updaters
   * Generate update functions for each field in a dictionary.
   *
   * @param fields: { [key: string]: string }
   * @return { [key: string]: (value) => void }
   **/
  const updaters = React.useMemo(() => {
    const result = {};
    for (const [key, field] of Object.entries(props.fields)) {
      result[key] = update(field);
    }
    return result;
  }, [props.fields, update]);

  const setField = React.useCallback(field => getValue => {
    setFormState(getValue);
    clearError(field);
  }, []);

  return {
    clearError,
    errors,
    mergeErrors,
    message,
    onSubmit,
    reset,
    _set,
    setError,
    setField,
    setMessage,
    state: formState,
    submitting,
    toggleCheckbox,
    update,
    updaters,
    validator
  };
}

