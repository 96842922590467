/**
 * @file social-buttons.js
 * This file provides buttons for social media links.
 *
 */
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { social_button_config } from '../config/social-buttons';

export default () => (
  <nav>
    <div className="nav-container">
      {social_button_config.map(button => (
        <button className="social" key={button.link}>
            <a href={button.link}>
                <img src={button.image} alt={button.name} />
            </a>
        </button>
      ))}
    </div>
  </nav>
)

