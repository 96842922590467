import React from 'react';
import { useForm, useManageUser } from 'Lib/hooks';
import { Form, Textarea, TextInput } from '../';
import { createInquiry } from 'Lib/hooks';
import { Modal, useModal } from '../../Modals';

const fields = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  MESSAGE: 'message'
}

export const useContactForm = (onResolve, onReject) => {
  const form = useForm({fields});

  /**
   * The user of this hook may specify their own resolve and reject behavior
   * to be run before the common resolve/reject behavior.
   *
   * Any error encountered is re-thrown.
   **/
  const registerInquiry = state => (
    createInquiry(state)
      .then(onResolve(form))
      .catch(error => {
        console.error(error);
        onReject(error);
        throw error;
      })
  )

  const onSubmit = form.onSubmit(registerInquiry);

  return { form, onSubmit }
}

export const ContactForm = props => {

  const { form, onSubmit } = props;
  const formClass = props.className || "";

  return (
    <Form
      className={formClass}
      name={props.name}
      onSubmit={onSubmit}
      validator={form.validator}
    >
      <TextInput
        className={"first-name"}
        error={form.errors[fields.FIRST_NAME]}
        label={"First Name"}
        name={fields.FIRST_NAME}
        onChange={form.updaters.FIRST_NAME}
        value={form.state[fields.FIRST_NAME]}
      />
      <TextInput
        className={"last-name"}
        error={form.errors[fields.LAST_NAME]}
        label={"Last Name"}
        name={fields.LAST_NAME}
        onChange={form.updaters.LAST_NAME}
        value={form.state[fields.LAST_NAME]}
      />
      <TextInput
        className={"email"}
        error={form.errors[fields.EMAIL]}
        label={"Email"}
        name={fields.EMAIL}
        onChange={form.updaters.EMAIL}
        required
        validateOnBlur
        value={form.state[fields.EMAIL]}
      />
      <Textarea
        className={"message"}
        error={form.errors[fields.MESSAGE]}
        label={"Message"}
        name={fields.MESSAGE}
        onChange={form.updaters.MESSAGE}
        required
        validateOnBlur
        value={form.state[fields.MESSAGE]}
      />
    </Form>
  );
}


