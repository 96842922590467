import React from 'react';
import { deleteKey } from 'Lib/util';

/**
 * @function useFormValidation
 * This hook manages field validation for a form. It maintains a registry of
 * validation functions for the form fields and provides methods for checking
 * them.
 *
 * To validate a field, the field needs to be registered with this component.
 **/
export const useFormValidation = props => {
  const { formState, setError, setErrors } = props;
  const [validators, setValidators] = React.useState({});

  /**
   * @function checkField
   * Test a single field for validity.
   *
   * @param field: string
   * @param value: any
   * @param testFn: (value: any) => void
   **/
  const checkField = React.useCallback((field, value, testFn) => {
    try {
      testFn(value);
      return [true];
    } catch (error) {
      console.error(`Field ${field} failed to validate. ${error.message}`);
      setError(field, error.message);
      return [false, error.message];
    }
  }, []);

  const registerValidator = React.useCallback((field, fn) => {
    setValidators(prev => ({ ...prev, [field]: fn }));
  }, []);

  const unregisterValidator = React.useCallback(field => {
    setValidators(prev => deleteKey(field, prev));
  }, []);

  const validateForm = () => {
    const errors = {};
    for (const [field, fn] of Object.entries(validators)) {
      const [isValid, error] = checkField(field, formState[field], fn);
      if (!isValid) errors[field] = error;
    }
    if (Object.keys(errors).length) {
      setErrors(errors);
      return false;
    }
    return true;
  }

  return {
    checkField,
    register: registerValidator,
    unregister: unregisterValidator,
    validate: validateForm
  };
}

