import React from 'react';

/**
 * @function useFieldValidator
 * Handle the common validation behavior of a single field. This component
 * registers/unregisters the field with the form's validation manager upon
 * mount/unmount. It returns the validation function it registers.
 *
 * This is intended to be used by form contols in combination with the
 * useForm hook.
 *
 * @param test: (required?: boolean) => (value: any) => void
 **/
export const useFieldValidator = props => {

  const fieldTest = React.useMemo(() => props.test(!!props.required), []);

  const validate = e => {
    props.validator?.checkField(props.name, e.target.value, fieldTest);
  }

  /**
   * Side effect:
   * Register the field validator with the form manager.
   **/
  React.useEffect(() => {
    if (props.validator) {
      props.validator?.register(props.name, fieldTest);
      return () => props.validator?.unregister(props.name);
    }
    return () => {};
  }, []);

  return validate;
}

