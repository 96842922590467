import React from "react";
import { classNames as cl } from 'Lib';
import { FieldErrorMessage } from './';

export const Input = React.memo(props => {

  const onChange = props.onChange || function() {};
  const update = e => onChange(e.target.value);

  return (
    <>
      <label
        className={props.required ? "required" : ""}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <input
        className={cl({
          control: true,
          error: props.error
        })}
        name={props.name}
        onBlur={props.onBlur}
        onChange={update}
        onFocus={props.onFocus}
        type={props.type}
        value={props.value || ""}
      />
      <FieldErrorMessage error={props.error} />
    </>
  );
});

