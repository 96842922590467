import React, { Component } from 'react';
import Page from 'Components/pages/page';
import { Cover, Resume } from './';
import { ROOT_URL } from '../../../lib/network';

export default () => {

  //React.useEffect(() => {
  //  console.log("FETCH");
  //  fetch(`${ROOT_URL}/`).then(res => console.log(res))
  //}, [])

  return (
    <Page className="homepage">
      <Cover />
      <Resume />
    </Page>
  );
}

