import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route } from 'react-router-dom';
import { BrowserRouter as Router, browserHistory } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer from 'Reducers';
import Root from 'Components/root';

const store = configureStore({ reducer: rootReducer })

const App = () => (
  <Provider store={store}>
    <Router>
      <Root />
    </Router>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById('jackbrown-io'));

