import {
  ActivateUser,
  Contact,
  Home,
  RegisterUser,
  NotFound
} from 'Components/pages';

export const routes = {
  home: { path: "/", exact: true, component: Home },
  //activateUser: { path: "/activate/:code", component: ActivateUser },
  contact: { path: "/contact", component: Contact },
  //register: { path: "/register", component: RegisterUser },
  notFound: { path: "/(.+)", component: NotFound }
};

export const mainNavigation = [
  //routes.activateUser,
  //routes.register,
  routes.home,
  routes.contact,
  routes.notFound
]

