import React from 'react';
import { API_URL, GET, request } from 'Lib/network';
import { DualRing as LoadingSpinner } from 'Components/loading';
import { ErrorIcon } from 'Components/error';
import { ResumeEntry } from './';

const RESUME_ENDPOINT = `${API_URL}/resume`;

const FullCenter = ({children}) => (
  <div className="full-center">
    {children}
  </div>
)

export const Resume = props => {

  const [resumeEntries, setResumeEntries] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [loadError, setLoadError] = React.useState(false);

  const handleLoadError = error => {
    console.error(error);
    setLoadError(true);
  }

  const handleResponse = res => {
    //console.log(res);
    setLoadError(false);
    setResumeEntries([...res.data.entries]);
  }

  React.useEffect(() => {
    request(GET, RESUME_ENDPOINT)
      .then(handleResponse)
      .catch(handleLoadError)
      .then(() => { setLoading(false) });
  }, []);

  if (loadError) {
    return (
      <FullCenter>
        <ErrorIcon />
      </FullCenter>
    )
  }

  if (loading) {
    return (
      <FullCenter>
        <LoadingSpinner />
      </FullCenter>
    )
  }

  return (
    <section className="home-intro">
      {(resumeEntries.length > 0) && (
        <ul className="resume">
          {resumeEntries.map(entry => (
            <ResumeEntry key={entry.id} entry={entry} />
          ))}
        </ul>
      )}
    </section>
  )
}

