/**
 * @file contact.js
 * This file provides the Contact page.
 *
 */
import React, { Component } from 'react';
import Page from 'Components/pages/page';
import { ContactForm } from 'Components/form/forms';
import { Submit } from 'Components/form';
import { useContactForm } from 'Components/form/forms/contact-form';
import { Modal, useModal } from '../../Modals';

const FORM_NAME = "contact-form";

export default () => {

  const afterSubmit = form => () => {
    form.reset();
    modalControls.show();
  }

  const onError = error => {
    console.error(error);
  }

  const contactForm = useContactForm(afterSubmit, onError);
  const modalControls = useModal();
  const { message, submitting } = contactForm.form;

  // TEST ONLY:
  //React.useEffect(() => { modalControls.show() }, [])

  return (
    <Page className="contact">
      <section>
        <h1>Contact</h1>
        <p className="subtitle">
          {"If you'd like to know more about the services I offer, or if you'd just like to say hello, please write me a message using the form below."}
        </p>
        <p className="subtitle">
          {"The messages are sent directly to my email inbox and I strive to answer all inquiries as soon as possible."}
        </p>
        <ContactForm
          {...contactForm}
          className="modal"
          name={FORM_NAME}
        />
        <div className="form-controls">
          <div className={`error form-error-message ${message ? 'active' : ''}`}>
            {message}
          </div>
          <Submit form={FORM_NAME} submitting={submitting} />
        </div>
      </section>
      <Modal className="form-success" controls={modalControls}>
        <h2>{"Success!"}</h2>
        <p>{"Thanks for reaching out, I'll be in touch soon!"}</p>
        <button className="primary-color" onClick={modalControls.hide}>{"Okay"}</button>
      </Modal>
    </Page>
  );
}

